import React from 'react';
import * as styles from './sideBar.module.css';

const SideBar = () => (
  <div className={styles.sideBar}>
    <a
      className={styles.social}
      href='https://github.com/eloone'
      target='_blank'
      rel='noopener'
      rel='noreferrer'
    >
      <i className='fab fa-github-square' />
    </a>
  </div>
);

export default SideBar;

import React from 'react';
import * as styles from '../layout.module.css';

const Footer = ({ currentPage }) => (
  <div className={styles.mySiteFooter}>
    <div className={styles.footerLeft}>{currentPage?.footer}</div>
    <div className={styles.footerRight}>
      &copy; {new Date().getFullYear()} release version 28d6d (latest).
    </div>
  </div>
);

export default Footer;

// extracted by mini-css-extract-plugin
export const carousel = "Carousel-module--carousel--2Xdau";
export const navigation = "Carousel-module--navigation--22bUz";
export const active = "Carousel-module--active--ShqQI";
export const slide = "Carousel-module--slide--1rhFl";
export const carouselControlNextIcon = "Carousel-module--carousel-control-next-icon--1WPrC";
export const carouselControlPrevIcon = "Carousel-module--carousel-control-prev-icon--3Hnd2";
export const next = "Carousel-module--next--1MfDl";
export const previous = "Carousel-module--previous--gvmMt";
export const carouselControlNext = "Carousel-module--carousel-control-next--CtXwG";
export const carouselControlPrev = "Carousel-module--carousel-control-prev--3NH3y";
export const carouselItem = "Carousel-module--carouselItem--1zqGz";
export const carouselCaption = "Carousel-module--carouselCaption--Ovjoj";
export const imageWrapper = "Carousel-module--imageWrapper--34jBR";
import React from 'react';

const Youtube = (props) => (
  <center style={{ marginTop: '3rem' }}>
    <iframe
      width='800'
      height='600'
      src={`https://www.youtube.com/embed/${props.id}`}
      frameBorder='0'
      allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
      allowFullScreen
      title={props?.title}
    />
  </center>
);

export default Youtube;

// extracted by mini-css-extract-plugin
export const mySite = "layout-module--mySite--1UhTW";
export const mySiteContainer = "layout-module--mySiteContainer--Jsp3Y";
export const mySiteContent = "layout-module--mySiteContent--14dcm";
export const comments = "layout-module--comments--XU-WA";
export const mySiteContentWrapper = "layout-module--mySiteContentWrapper--WwU2q";
export const mySiteTabs = "layout-module--mySiteTabs--3QMmp";
export const tab = "layout-module--tab--Uu7bx";
export const currentTab = "layout-module--currentTab--hlUuk";
export const mySiteBlock = "layout-module--mySiteBlock--3baTC";
export const mySiteFooter = "layout-module--mySiteFooter--aiNZ6";
export const footerLeft = "layout-module--footerLeft--6auup";
export const footerRight = "layout-module--footerRight--3yYUa";
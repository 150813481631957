import React from 'react';
import reactStringReplace from 'react-string-replace';
import * as styles from './colorfulText.module.css';

class ColorfulText extends React.Component {
  render() {
    return (
      <div className={styles.main}>
        {(Array.isArray(this.props.children)
          ? this.props.children
          : [this.props.children]
        ).map((c, j) => {
          let replacedText = reactStringReplace(
            c.props && c.props.children ? c.props.children : c,
            /(\d+)/g,
            (match) => (
              <span className={styles.digits} key={Math.random()}>
                {match}
              </span>
            )
          );

          const keywords = (words) =>
            new RegExp(`(${words.map((w) => `\\s+${w}\\s+`).join('|')})`, 'g');

          //(?<!^)[A-Z][a-z]+ = Capitalized words not at the beginning of line, can't be used because <!^ not supported in Safari
          //instead we use (?!^)[A-Z][a-z]+ for Safari
          // do not use a name at beginning of sentence for this to work
          replacedText = reactStringReplace(
            replacedText,
            /((?!^)[A-Z][a-z]+|\.[^\.A-Z]+[A-Z]|[A-Z]{2,}|!\s[A-Z][a-z]+)/g,
            (match) =>
              match.includes('.') || match.includes('!') ? (
                match
              ) : (
                <span className={styles.names} key={Math.random()}>
                  {match}
                </span>
              )
          );

          if (this.props.colorKeywords) {
            replacedText = reactStringReplace(
              replacedText,
              keywords([
                'then',
                'also',
                'if',
                'because',
                'or',
                'and',
                'in',
                'this',
              ]),
              (match) => (
                <span className={styles.keywords} key={Math.random()}>
                  {match}
                </span>
              )
            );
          }

          if (this.props.colorPunctuation) {
            replacedText = reactStringReplace(
              replacedText,
              /([,\.!])/g,
              (match) => (
                <span className={styles.punctuation} key={Math.random()}>
                  {match}
                </span>
              )
            );
          }

          return c?.props?.originalType === 'br' ? (
            <br />
          ) : c?.props?.originalType ? (
            <c.props.originalType className={c.props.className} key={j}>
              {replacedText}
            </c.props.originalType>
          ) : (
            replacedText
          );
        })}
      </div>
    );
  }
}

export default ColorfulText;

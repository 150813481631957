import React, { useState } from 'react';
import * as styles from './Carousel.module.css';

const Carousel = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { children: propsChildren } = props;
  const children = Array.isArray(propsChildren)
    ? propsChildren.filter((child) => typeof child !== 'string')
    : [propsChildren];

  const goPrevious = () => {
    if (activeIndex - 1 >= 0) {
      setActiveIndex(activeIndex - 1);
    } else {
      setActiveIndex(children.length - 1);
    }
  };

  const goNext = () => {
    if (activeIndex + 1 <= children.length - 1) {
      setActiveIndex(activeIndex + 1);
    } else {
      setActiveIndex(0);
    }
  };

  const onKeyDown = (e) => {
    if (e.keyCode === 39) {
      goNext();
    } else if (e.keyCode === 37) {
      goPrevious();
    }
  };

  return (
    <div
      tabIndex='-1'
      role='presentation'
      onKeyDown={onKeyDown}
      className={styles.carousel}
    >
      <div className={styles.slide}>{children[activeIndex]}</div>
      <ol className={styles.navigation}>
        {children.map((child, i) => (
          <li
            key={i}
            onClick={() => setActiveIndex(i)}
            onKeyDown={() => {}}
            className={i === activeIndex ? styles.active : ''}
            role='presentation'
          />
        ))}
      </ol>
      <button
        tabIndex='-1'
        aria-label='Go previous '
        role='button'
        title='Previous'
        onKeyDown={onKeyDown}
        className={styles.previous}
        onClick={goPrevious}
      />
      <button
        tabIndex='-1'
        aria-label='Go next'
        role='button'
        title='Next'
        onKeyDown={onKeyDown}
        className={styles.next}
        onClick={goNext}
      />
    </div>
  );
};

export const CarouselItem = (props) => {
  const children = props.children
    .filter((child) => typeof child !== 'string')
    .map((child, i) => {
      if (child.props.className === 'gatsby-resp-image-wrapper') {
        return (
          <div
            key={i}
            style={{ maxWidth: child.props.style.maxWidth }}
            className={styles.imageWrapper}
          >
            {child}
          </div>
        );
      }
      return child;
    });
  return <div className={styles.carouselItem}>{children.reverse()}</div>;
};

export const CarouselCaption = (props) => (
  <div className={styles.carouselCaption}>{props.children}</div>
);

export default Carousel;

import React from 'react';
import { Link } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';
import { Helmet } from 'react-helmet';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import SideBar from './SideBar/SideBar';
import * as styles from './layout.module.css';
import ColorfulText from '../ColorfulText/ColorfulText';
import colorfultext from '../ColorfulText/ColorfulText';
import carousel, { CarouselItem, CarouselCaption } from '../Carousel/Carousel';
import Youtube from '../Shortcodes/Youtube';
import useSiteMetadata from '../../utils/useSiteMetadata';
import favicon from '../../assets/favicon.png';

type CurrentPageType = { slug: string; title: string; name: string };
type LayoutProps = {
  children: React.ReactNode;
  className?: string;
  currentPage?: CurrentPageType;
};

const Layout = ({ children, className, currentPage }: LayoutProps) => {
  const shortcodes = {
    Link,
    ColorfulText,
    colorfultext,
    carousel,
    carouselitem: CarouselItem,
    carouselcaption: CarouselCaption,
    Youtube,
  };
  const siteData = useSiteMetadata();
  return (
    <div className={styles.mySite}>
      <Helmet>
        <meta charSet='utf-8' />
        <meta name='description' content="Eloone's home." />
        <title>{`${currentPage.title} | ${siteData.siteHeadline}`}</title>
        <link rel='canonical' href='https://www.eloone.dev/' />
        <link rel='icon' type='image/png' href={favicon} sizes='32x32' />
        <link rel='preconnect' href='https://fonts.gstatic.com' />
        <link
          href='https://fonts.googleapis.com/css?family=PT+Mono:400,700|Cabin+Sketch:400&display=swap'
          rel='stylesheet'
          async
        />
        <html lang='en' />
      </Helmet>
      <Header currentPage={currentPage} />
      <div className={styles.mySiteBlock}>
        <SideBar />
        <div className={styles.mySiteContainer}>
          <div className={styles.mySiteTabs}>
            <Link
              className={currentPage.name === 'home' ? styles.currentTab : ''}
              to='/'
            >
              index.js
            </Link>
            <Link
              className={currentPage.slug === '/about' ? styles.currentTab : ''}
              to='/about'
            >
              About
            </Link>
            <Link
              className={
                currentPage.name === 'projects' || currentPage.name === 'tag'
                  ? styles.currentTab
                  : ''
              }
              to='/projects'
            >
              Projects
            </Link>
            {currentPage.name === 'project' && (
              <Link className={styles.currentTab} to={currentPage.slug}>
                {currentPage.title}
              </Link>
            )}
          </div>
          <div className={styles.mySiteContent}>
            {currentPage.name === 'home' ? (
              <pre className={styles.comments}>
                <code>
                  {`
/*
*
*
*
*
*
*
*
*
*
*
*
*
*
*
*
*
*
*
*
*
*
*
*
*
*
*
*
*
*
*
*
*
*/
`}
                </code>
              </pre>
            ) : null}
            <div className={styles.mySiteContentWrapper}>
              <MDXProvider components={shortcodes}>{children}</MDXProvider>
            </div>
          </div>
        </div>
      </div>
      <Footer currentPage={currentPage} />
    </div>
  );
};

export default Layout;

import React from 'react';
import { Link } from 'gatsby';
import replaceSlashes from '../../../utils/replaceSlashes';
import useSiteMetadata from '../../../utils/useSiteMetadata';
import * as styles from './header.module.css';

type CurrentPageType = { slug: string; title: string };
type HeaderProps = { currentPage?: CurrentPageType };

const Header = ({ currentPage }: HeaderProps) => {
  const { siteTitle, externalLinks, basePath } = useSiteMetadata();
  return (
    <header>
      <div className={styles.mySiteHeader}>
        <Link
          to={replaceSlashes(`/${basePath}`)}
          aria-label={`${siteTitle} - Back to home`}
        >
          <h1>{siteTitle}</h1>
        </Link>
      </div>
    </header>
  );
};

export default Header;
